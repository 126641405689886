@import "style.scss";
@import "assets/variables";

.sf-header{
	background-color: #3cb6e3;
	color: #FFFFFF;
	

	.sf-hero{
		background-color: #3cb6e3;
		color: #FFFFFF;
		
		h1{
			width: 508px;
			font-size: 45px;
			letter-spacing: -1.4px;
		}

		p{
			color: rgba(255, 255, 255, 0.75);
			font-size: 36px;
			line-height: 48px;
		}

		.sf-hero-button{
			width: 300px;
			height: 70px;

			margin: 0 auto;
			
			a{
				@extend .sf-button;
				display: block;
				-webkit-border-radius: 80px;
				-moz-border-radius: 80px;
				border-radius: 80px;
				letter-spacing: 4px;
				font-size: 22px;
				font-weight: 400;
				line-height: 16px;
				padding: 28px 0 26px 0;
			}
		}

		.mockup{
			text-align: center;
		}
	
	}
}

.sf-header-foot{
	background-color: #ffffff;
	padding-top: 23px;
	padding-bottom: 22px;
	color:  #666c70;
	
	.sf-hf-button{
		display: inline-block;
		margin-right: 4px;
		border-radius: 7px;
		font-size: 18px;
		padding: {
			top:9px;
			left:9px;
			right:9px;
			bottom:7px;
		}
		line-height: 15px;
		letter-spacing: 2px;
	}

	.whitepaper{
		text-transform: uppercase;
		font-family: Montserrat;
		font-weight: 700;
	}

	.read-more{
		font-family: Montserrat;
		font-size: 18px; 
		font-weight: 700;
		color: rgba(85, 85, 85, 0.45);
		margin-left: 18px;
	}

	&:hover{
		background-color: #f1f1f1;	
	}
}

.sf-logos {
	@extend .gradient;
	text-align: center;
	padding: 23px 0;

	background: rgb(245,245,245); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y1ZjVmNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(245,245,245,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(245,245,245,1)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(245,245,245,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(245,245,245,1) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(245,245,245,1) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(245,245,245,1) 0%,rgba(255,255,255,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */


	.row{
		margin: 0 auto;

		div{
			width: 128px;
			display: table-cell;

			img {
				height: 40px;
			}

			p {
				font-family: 'Montserrat';
				font-size: 13px;
				font-weight: 100;
				margin-top: 8px;
				margin-bottom: 0;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}

	p {
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 100;
		margin-bottom: 16px;
	}
}

.sf-boxes{
	background-color: #eaeaea;
	padding: 40px 0;
    .row:last-child{
        .col-md-6:last-child{
            .sf-box{
                margin-bottom: 0;
            }
        }
    }
    a{
        text-decoration: none;
		outline: none;
    }
	h1 {
		text-align: center;
		font-family: 'Montserrat';
		font-size: 27px;
		color: black;
		font-weight: 600;
		line-height: 34px;
	}
	h2 {
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 16px;
	}
	h3{
		text-align: center;
		color: black;
	}
	p {
		text-align: center;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 100;
	}
	.play {
		text-align: center;
    	height: 76px;
		margin-bottom: 0px;

		i {
			font-size: 78px;
			color: #e74c3c;
		}
	}
	.sf-box{
		border-radius: 5px;
		background-color:  #ffffff;
		width: 560px;
		margin-bottom: 20px;

		.box-content{
			padding: {
				top: 50px;
				left: 42px;
				bottom: 31px;
				right: 26px;
			}

			h3{
				color: #3c3d41;
				margin-bottom: 17px;
			}

			p{
				text-align: start;
				color: #838d8f;
				font-size: 24px;
				margin-bottom: 20px;
			}

			img{
				margin-left: 181px;
				margin-bottom: 26px;
			}
		}
	}
	
	.no-margin-bottom{
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.box-button{
	@extend .styless-anchor;
	font-family: Montserrat;
	font-size: 14px;
	color: #e74c3c;
	cursor: pointer;
	font-weight: 700;

	span{
		font-size: 15px;
		margin-left: 4px;
		color: #838d8f;
	}
}

.tab-wrap {
	-webkit-transition: 0.3s box-shadow ease;
	transition: 0.3s box-shadow ease;
	max-width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	position: relative;
	list-style: none;
	margin: 40px 0 0 0;
}

.tab,
.accordion {
	display: none;
}

.tab:checked + label {
	font-weight: 700;
	color: #e74c3c;
    border-bottom: 3px solid #e74c3c;
	cursor: default;
	padding-bottom: 33px;
}

.tab + label {
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
	border-bottom: 1px solid #838d8f;
	display: block;
	text-decoration: none;
	color: #3c3d41;
	-webkit-box-flex: 3;
		-ms-flex-positive: 3;
			flex-grow: 3;
	-webkit-user-select: none;
	   -moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
	text-align: center;
	-webkit-transition: 0.3s background-color ease, 0.3s box-shadow ease;
	transition: 0.3s background-color ease, 0.3s box-shadow ease;
	height: 50px;
	box-sizing: border-box;
	padding: 15px;
}
.accordion + label {
	font-family: 'Montserrat';
	cursor: pointer;
	color: #3c3d41;
	background: #fff;
	border: 1px solid #3c3d41;
	border-radius: 4px;
	padding: 1rem;
	font-size: 1rem;
	font-weight: 500;
	width: 100%;
	text-align: left;
}

.accordion + label:before {
	content: '';
    display: inline-block;
    top: 0;
    left: 0;
    background-image: url(../img/accordion-arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    vertical-align: middle;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    width: 1.75rem;
    height: 1.75rem;
}

.accordion:checked + label:before {
	-webkit-transform: rotate(90deg) translateX(0%);
    transform: rotate(90deg) translateX(0%);
}

.tab-content {
	background-color: transparent;
	position: absolute;
	padding: 16px 0;
	width: 100%;
	z-index: -1;
	opacity: 0;
	left: 0;
	-webkit-transform: translateY(-3px);
			transform: translateY(-3px);
	border-radius: 6px;

	.gap {
		padding-right: 16px;
	}

	.tab-description {
		font-size: 20px;
		color: #07152c;
		line-height: initial;
		font-family: 'Montserrat';
		padding: 16px 16px 32px 0;
	}

	figure {
		padding-bottom: 32px;
	}

	img {
		width: 100%;
	}

	h3 {
		text-align: start;
		font-size: 16px;
		color: #3c3d41;
	}

	p {
		text-align: start;
		font-size: 14px;
		margin-bottom: 32px;
	}

}

@media (max-width: $xs-screen){
	.tab + label {
		display: none !important;
	}

	#accordion1:checked ~ #content1,
	#accordion2:checked ~ #content2,
	#accordion3:checked ~ #content3,
	#accordion4:checked ~ #content4 {
		opacity: 1;
		-webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
		transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
		transition: 0.5s opacity ease-in, 0.8s transform ease;
		transition: 0.5s opacity ease-in, 0.8s transform ease, 0.8s -webkit-transform ease;
		position: relative;
		top: 0;
		z-index: 100;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		text-shadow: 0 0 0;
	}

	.tab-content {
		.tab-description {
			font-size: 22px;
		}

		h3 {
			font-size: 20px;
		}

		p {
			font-size: 18px;
			line-height: 26px;
			margin-bottom: 16px;
		}
	
	}
}

@media (min-width: $md-screen) {

	.accordion + label {
		display: none !important;
	}

	#tab1:checked ~ #content1,
	#tab2:checked ~ #content2,
	#tab3:checked ~ #content3,
	#tab4:checked ~ #content4 {
		opacity: 1;
		-webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
		transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
		transition: 0.5s opacity ease-in, 0.8s transform ease;
		transition: 0.5s opacity ease-in, 0.8s transform ease, 0.8s -webkit-transform ease;
		position: relative;
		top: 0;
		z-index: 100;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		text-shadow: 0 0 0;
	}
}

.sf-awards{
	background-color: #ffffff;
	padding: 40px 0;
	text-align: center;
}

.sf-why{
	background-color: #2e2e30;
	padding: 40px 0 53px 0;
	color: #b1bfc2;

	h3{
		color: #ffffff;
		margin-bottom: 39px;
		text-align: center;
	}

	.comment{
		text-align: center;
		margin-bottom: 60px;

        &:last-child{
            margin-bottom: 0;
        }        

        & > p{
			font-size: 22px;
			line-height: 24px;
			margin-bottom: 29px;
		}
        
		.author{
			div{
				display: inline-block;
				vertical-align: middle;
			}
			
			img{
				margin-right: 17px;
			}
			
			p{
				text-align: left;
				font-family: Montserrat;
				font-size: 16px;
				line-height: 22px;

				&:first-child{
					color:  #fefefe;
					font-weight: 700;
					text-transform: uppercase;
					letter-spacing: 4px;
				}

				&:last-child{
					color:  #3cb6e3;
					font-weight: 400;
				}
			}
		}
	}
}

.sf-news{
	background-color: #3b3c40;
	padding: 36px 0;
	color: #ffffff;

	h3{
		margin-bottom: 28px;
	}

	p{
		font-size: 14px;
		color: rgba(255, 255, 255, 0.6);
	}

	.input-group{
		margin-top: 4px;

		input{
			height: 65px;
			padding: {
				left: 27px;
				bottom: 23px;
				top: 25px;
			}

			font-size: 17px; 
			line-height: 20.4px;
		}

		.input-group-btn .btn-default{
			width: 66px;
			height: 65px;
		}
	}
}

@media (max-width: $xs-screen) {
	.sf-hero{
		h1{
			margin-bottom: 43px;
		}

		p{
			margin-bottom: 37px;
		}

		.sf-hero-button{
			border-radius: 80px;
		}
	}
	.above-mockup{
		position: relative;
		margin-top: 37px;
		margin-bottom: 37px;
	}

	.sf-header-foot{
		.read-more{
			display: block;
			text-align: center;
			margin-top: 11px;
			letter-spacing: 2px;
		}
	}

	.box-button{
		font-size: 18px;
	}

	.sf-news{
		p{
			font-size: 24px;
			line-height: 24px;
			margin-bottom: 41px;
		}

        .input-group{
            input{
                font-size: 25px;
            }
        }
	}
}

@media (min-width: $md-screen) { 
	.sf-header{
		.sf-hero{
			overflow-y: visible;

			h1{
				margin: {
					top: 156px;
				}
			}

			p{
				font-size: 23.78px;
				line-height: 31.44px;
				width: 470px;
				margin-top: 28px;
			}

			.sf-hero-button{

				width: 180px;
				height: 45px;
				margin: 48px 0 0 -1px;

				a{
					font-size: 12px;
					line-height: 7px;
					font-weight: 100;
					padding: 18px 0 19px 0;
					letter-spacing: 2px;
				}
			}
		}

		.behind-mockup{
			text-align: left;
			position: relative;
			height: 604px;
			width: 638px;

			img{
				position: absolute;
				top: 29px;
				left: 30px;
				height: 610px;
			}
		}
	}

	.sf-header-foot{
		.sf-hf-button{
			padding: 6px 6px 4px 6px;

			border-radius: 4px;
			font-size: 10px;
			margin-right: 18px;
			
			line-height: 7px;
			letter-spacing: 1px;

		}

		.read-more{
			font-size: 10px; 
			font-weight: 700;
			line-height: 12px; 
			letter-spacing: 1px;
		}
	}


	.sf-logos {
        padding: 23px 0;
		.row{
			width: 640px;
		}
	}

	.sf-boxes{
		padding-bottom: 0px;

        .row:last-child{
            .sf-box{
                margin-bottom: 0;
            }
        }
		.sf-box{
			width: 470px;
			height: 300px;
			margin-bottom: 21px;
			position: relative;

			.box-content{
				padding: {
					top: 47px;
					left: 29px;
					right: 40px;
					bottom: 29px;
				}

				h3{
					width: 256px;
					margin-bottom: 28px;
				}

				p{
					width: 247px;
					font-size: 14px;
				}

				img{
					margin-top: 43px;
					margin-left: 17px; 
				}
			}
		}
		
		.no-margin-bottom{
			margin-bottom: 0;
		}
	}

	.box-button{
		padding-top: 3px;
	}

	.sf-why{
		filter: none;
		background: rgb(46,49,52); /* Old browsers */
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJlMzEzNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI2JSIgc3RvcC1jb2xvcj0iIzJmM2EzZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc3JSIgc3RvcC1jb2xvcj0iIzMyNTE1ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzMjU3NjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  rgba(46,49,52,1) 0%, rgba(47,58,63,1) 26%, rgba(50,81,94,1) 77%, rgba(50,87,102,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(46,49,52,1)), color-stop(26%,rgba(47,58,63,1)), color-stop(77%,rgba(50,81,94,1)), color-stop(100%,rgba(50,87,102,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(46,49,52,1) 0%,rgba(47,58,63,1) 26%,rgba(50,81,94,1) 77%,rgba(50,87,102,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(46,49,52,1) 0%,rgba(47,58,63,1) 26%,rgba(50,81,94,1) 77%,rgba(50,87,102,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(46,49,52,1) 0%,rgba(47,58,63,1) 26%,rgba(50,81,94,1) 77%,rgba(50,87,102,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(46,49,52,1) 0%,rgba(47,58,63,1) 26%,rgba(50,81,94,1) 77%,rgba(50,87,102,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e3134', endColorstr='#325766',GradientType=0 ); /* IE6-8 */
		padding-bottom: 53px;

		.comment{
			margin-bottom: 52px;
			
			& > p{
				font-size: 22px;
				margin-bottom: 24px;
			}

			.author{
				img{
					margin-right: 21px;
				}
				
				p{
					font-size: 12px;
					text-align: left;
                    line-height: 15px;
					&:first-child{
						letter-spacing: 2.4px;
					}
				}
			}
		}
	}

	.sf-news{
		.col-md-8{
			text-align: right;
		}

		h3{
			margin-bottom: 12px;
		}

		p{
			width: 285px;
		}

		.input-group{
			margin-left: 12px;
			width: 631px;
		}

		input{
			width: 565px;
		}
	}
}

