@import url(//fonts.googleapis.com/css?family=PT+Serif:400,400italic,700);
@import "assets/variables";
@import "iubenda-cs-banner";

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Bold.ttf);
	font-weight: 700;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Regular.ttf);
	font-weight: 400;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Light.otf);
	font-weight: 100;
}

@mixin heading($h, $fs, $lh, $ls){
	h#{$h}{
		font-size: #{$fs}px;
		line-height: #{$lh}px;
		font-weight: 700;
		margin: 0;

		@if $ls != 0 {
			letter-spacing: #{$ls}px;
		}

		@content
	}
}

figcaption {
	text-align: center;
	font-size: 12px;
	line-height: normal;
    margin: 9px 0 0;
}

.play-gif {
	display: none;
}

.gp-gif-element {
	pointer-events: none;
	cursor: default;
}

.wide{
	width: 100% !important;
}

.uppercase{
	text-transform: uppercase;
}

.styless-anchor{
	color: #ffffff;
	text-decoration: none;
}

.gradient{
	filter: none;
}

.sf-button{
	@extend .uppercase;
	@extend .styless-anchor;

	cursor: pointer;

	font-family: 'Montserrat';
	color:  #ffffff;
	font-weight: 700;

	text-align: center;

	background-color: #e74c3c;
}

a.sf-main-button {
	@extend .sf-button;
	width: 180px;
	font-size: 12px;
	font-weight: 100;
	padding: 13px 0 13px 0;
	letter-spacing: 2px;
	-webkit-border-radius: 80px;
	-moz-border-radius: 80px;
	border-radius: 80px;
	display: inline-block;
}

a.sf-secondary-button {
	@extend .sf-button;
	background-color: transparent;
	font-weight: 100;
	font-size: 12px;
	width: 180px;
	display: inline-block;
	border: 2px;
	border-style: solid;
	border-color: rgba(255,255,255,0.6);
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 11px 0 11px 0;
	letter-spacing: 1.2px;
	margin-left: 18px;
}

.feature-p{
	margin-bottom: 32px;
	font-size: inherit;
}

.feature-h3{
	@extend .black;
	margin-bottom: 16px;
}

.italic{
	font-style: italic;
}

.black{
	color: #3c3d41
}

.bold{
	font-weight: 700;
}

.underline{
	text-decoration: underline;
}

body{
	overflow-x: hidden;
}

html, body{
	color: #838d8f;
	font: {
		family: 'PT Serif';
		size: 22px;
	};
	line-height: 34px;
	background-color: #444549;


}

html, html a{
	-webkit-font-smoothing: antialiased !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

p{
	margin: 0;
}

h1,h2,h3,h4,h5,h6{
	font:{
		family: 'Montserrat';
	};
}

a:hover, a:focus {
	text-decoration: none
}

@include heading(1, 46.9, 46, -1.4) {};
@include heading(2, 36, 26.4, 0) {};
@include heading(3, 22.78, 26.4, -0.89) {};
@include heading(4, 18, 13.2, 0) {};
@include heading(5, 11, 13.2, 0) {};
@include heading(6, 46, 59.8, 0) {};

div{
	overflow: visible;
}

.sf-menu-mobile{
	display: none;
}

.sf-page-content{
	background-color: #FFFFFF;
	max-width: 1200px;
	margin: 0 auto;
}

.wrapper-right{
	position: absolute;
	width: 100%;
}

.row{
	margin: 0;
	padding: 0;

	[class*="col-"]{
		padding: 0;
	}
}

.container, .container-size{
	width: 560px;
	padding: 0;
	overflow: visible;
	margin: 0 auto;
}

#ycb-modal {
	.modal-content {
		width: 770px;

		.modal-header {
			border-bottom: 0px;

			.modal-title {
				font-family: 'Montserrat';
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 32px;
			}
		}

		.modal-body {
			height: 792px;
			padding: 0;

			.ycb-frame{
				width:100%;
				height:821px;
				border:0px;
				background-color:transparent;
				margin-top: -34px;
			}
		}

		.close {
			margin-top: 0px;
		}
	}
}

//header
.sf-header{
	padding-top: 27px;

	.sf-navbar{
		@extend .uppercase;

		&, & a{
			font: {
				family: 'Montserrat';
				weight: 100;

			}

			letter-spacing: 1px;
		}



		.sf-logo{
			width: 48px;
			float: left;
		}

		.sf-menu{
			margin-left: 180px;
			float:left;

			ul{
				padding: 13px 0 0 0;
				margin: 0;
				list-style: none;
				text-align: right;

				li{
					display: inline;
					margin-right: 54px;

					&.no-margin-right, &:last-child{
						margin-right: 0px;
					};

					a{
						@extend .styless-anchor;
					};


				}


			}
		}

		.sf-menu.sf-menu-actions {
			float: right;
			margin-top: -13px;

			ul{
				li{
					margin-right: 20px;
				}
			}

			.sf-nav-button {
				width: 180px;
				display: inline-block;

				@extend .styless-anchor;

				border: 2px {
					style: solid;
					color: rgba(255, 255, 255, 0.6);
				}

				-webkit-border-radius: 30px;
				-moz-border-radius: 30px;
				border-radius: 30px;
				padding: 10.5px 0 11.5px 0;

				text-align: center;
				letter-spacing: 1.2px;
			}
		}
	}

	hr{
		margin:{
			top: 19px;
			bottom: 44px;
		};

		background-color:  rgba(255, 255, 255, 0.3);
		height: 2px;
		border-top: 0 !important;
	}
}


.sf-header.feature{
	background-size: cover;

	.sf-hero{
		padding-bottom: 30px;

		h1{
			font-size: 46.99px;


			color: #ffffff;
			text-align: center;
			margin-bottom: 22px;
		}

		p{
			font-size: 36px;
			color:  rgba(255, 255, 255, 0.75);

			text-align: center;
		}

		.arrow{
			&.right{
				text-align: left;
				padding-left: 90px;
			}

			&.left{
				text-align: right;
				padding-right: 90px;
			}

			margin-top: 22px;
			margin-bottom: 30px
		}
	}

	hr{
		margin-bottom: 128px;
	}
}
.sf-tour{
	background-color: #3cb6e3;
	color: #ffffff;
	text-align: center;
	padding: 62px 0;
	border: 0;
	border-bottom: 9px solid #3c87ae;
    &:hover{
        background-color: #3b97c7;
    }
	a{
		@extend .styless-anchor;
	}

	h1{
		font-size: 46px;
		letter-spacing: -1px;
	}

	p{
		font-size: 24px;
		font-weight: 400;
		line-height: 26.4px;
		color:  rgba(255, 255, 255, 0.75);
		margin-bottom: 0;
	}

	.row div{
		display: inline-block;
	}

	img{
		position: relative;
		margin-left: 43px;
		bottom: 32px;
	}
}




.input-group{
	input{
		border-radius: 4px;
		background-color:  rgba(0, 0, 0, 0.16);
		width: 100%;

		color: rgba(255, 255, 255, 0.6);

		font-weight: 400;
		font-family: 'Montserrat';

		border: 0;
	}

	.input-group-btn .btn-default{
		color: #ffffff;
		border: 0;
		background-color:  #e74c3c;
	}
}


.sf-footer{
	background-color:  #45464a;
	padding: 41px 0 ;
	color: #f7f7f7;
	a{
		@extend .styless-anchor;
		color: rgba(238, 238, 238, 0.56);
	}
	.float{
		float: left;
	}

	h5, h4{
		@extend .uppercase;
		margin-bottom: 29px;
		letter-spacing: 3.6px;

		&.m-bottom{
			margin-bottom: 27px;
		}
	}

	.footer-list{
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			font-family: 'Montserrat';
			color: rgba(238, 238, 238, 0.56);
			font-size: 18px;
			line-height: 18.2px;
		}
	}

	.l1, .l2{
		li{
			margin-bottom: 30px;

			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	.l2{
		.icon{
			text-align: center;
			vertical-align: text-bottom;
		}

		.icon-text{
			line-height: 1px;
		}

		.facebook-icon{
			content: url('../img/facebook.svg');
			margin: 0 13px 0px 5px;
		}
		
		.linkedin-icon{
			content: url('../img/linkedin.svg');
			margin: 0 10px 2px 3px;
			height: 13px;
			width: 13px;
		}

		.x-icon {
			content: url('../img/x.svg');
			margin: 0 10px 1px 3px;
			height: 13px;
			width: 13px;
		}

		.youtube-icon{
			content: url('../img/youtube.svg');
			margin: 0 8px 0px 0px;
			width: 18px;
			height: 15px;
		}
	}
}

.sf-one-column{
	background-color: #ffffff;

	padding: {
		top: 39px;
		bottom: 35px;
	}

	.row{
		margin: {
			bottom: 35px;
		}

		&:last-child{
			margin-bottom: 0;
		}

		p{
			@extend .feature-p;

			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	h3{
		@extend .feature-h3;
	}

	.box{
		box-sizing: content-box;
		border-width: 1px;
		border-color:  #eaeaea;
		border-style: solid;
		background-color:  #f9f9f9;
		box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.05);

		width: 439px;

		padding: {
			right: 41px;
			left: 58px;
			top: 30px;
			bottom: 29px;
		}
		margin-bottom: 24px;

		p, div{
			text-align: center;
		}

		p{
			width: 100% !important;
			color: #3c3d41;
			margin-bottom: 10px;
			font-size: 22px;
			line-height: 20px;
		}

		.actions.content, .row{
			width: 100%;
			margin: 0;
		}

		.actions.content{
			.arrows{
				margin-bottom: 16px;
			}

			.actions.row{
				font:{
					family: Montserrat;
					size: 18px;
					weight: 700;
				}
				letter-spacing: 1.2px;
				color:  #3cb6e3;

				.col-xs-4{
					text-align: center;

					&:first-child{
						text-align: left;
					}

					&:last-child{
						text-align: right;
					}
				}
			}
		}

	}

	form{
		margin-top: 43px;
	}

}

.sf-two-columns{
	.container{
		@extend .wide;
		.row{
			clear: both;
		}
	}

	.first, .second{
		.content{
			width: 560px;
			margin: 0 auto;
		}

		h3{
			@extend .feature-h3;
		}

		p{
			@extend .feature-p;

			&:last-child{
				margin-bottom: 0;
			}
		}

		&.full-col{
			.content{
				width: 100%;
			}
		}
	}

}

.sf-form{
	fieldset{
		padding: {
			top: 36px;
			right: 34px;
			left: 34px;
			bottom: 40px;
		}

		border-radius: 10px;
		border-width: 1px;
		border-color:  rgba(68, 69, 73, 0.2);
		border-style: solid;
	}

	label, input{
		display: block;
	}

	label{
		font-family: 'Montserrat';
		color:  #3a3d3f;
		font-size: 22px;
		margin-bottom: 24px;
		font-weight: 400;
	}

	input{
		border-radius: 5px;
		width: 100%;
		background-color:  #eeeeee;
		border: 0;
		border-top: 1px solid #dddddd;
		padding: 7px;
		height: 60px;
		margin-bottom: 30px;

		&:last-child{
			margin-bottom: 0;
		}
	}

	textarea{
		@extend input;
		height: 80px;
		resize: vertical;
	}

	input[type=checkbox]{
		display: none;


		& + label{
			margin-bottom: 21px;

			.checkbox{
				display: inline-block;
				margin-right: 20px;
				border-radius: 9px;
				background-color:  #eeeeee;
				width: 40px;
				height: 40px;
				border: 1px solid #b2d9b6;
				vertical-align: middle;
			}
		}

		&:checked + label .checkbox{
			background-color:  #88d68f;
			background-image: url('../img/check-mobile.png');
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.sf-button{
		border: 0;
		margin: 0 auto;
		padding: 19px 94px;
		letter-spacing: 4.4px;
		border-radius: 65px;
		margin-top: 40px;
		display: block;
	}

	p{
		font-size: 24px !important;
	}

	textarea{
		height: 140px;
	}
}

.whitepaper-link:hover{
	background-color: #404043;
}

// MOBILE CLASSES
@media (max-width: $xs-screen) {
    @-ms-viewport {
        width: 600px;
	}

	#ycb-modal {
		.modal-content {
			width: 100%;
		}
	}

	.sf-header.larger.feature .sf-hero p{
		margin-bottom: 405px;
	}
	body{
		overflow-x: hidden;
	}
	.feature-h3{
		font-size: inherit;
	}
	.open-menu{
		color: #ffffff;
		cursor: pointer;
	}

	.sf-menu-mobile{
		padding: {
			top: 40px;
			bottom: 60px;
		}

		z-index: 999999;
		width: 100%;
		position: absolute;
		top: 0;
		background-color: rgba(59, 60, 64, 0.95);

		.title{
			font-family: 'Montserrat';
			color: rgba(247, 247, 247, 0.35);
			font-size: 18px;
			float: left;
			margin-bottom: 39px;
		}

		.close-menu{
			float: right;
			font-family: 'Montserrat';
			color: #eeeeee;
			font-size: 20px;
			text-decoration: underline;
			cursor: pointer;
		}

		ul{
			padding: 0;
			list-style: none;
			li{
				margin-bottom: 50px;
				&:last-child{
					margin-bottom: 0;
				}

				a{
					font-family: 'Montserrat';
					color:  rgba(238, 238, 238, 0.7);
					font-size: 24px;
				}
			}
		}
	}

	.full, .full-xs{
		width: 100%;
		max-width: 640px;
	}

	.wrapper-right{
		margin-top: 50%;
	}

	.no-mobile{
		display: none !important;
	}


	.sf-navbar{
		.sf-menu{
			ul{
				line-height: 18px;
			}
		}
	}
	.sf-header.feature{
		.sf-hero{
			.arrow{
				img{
					width: 70px;
					height: 70px;
				}
			}
			p{
				margin-bottom: 209px;
			}
		}
	}

	.tour-wrapper{
		background: rgb(255,255,255); /* Old browsers */
		background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(68,69,73,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(68,69,73,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(68,69,73,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(68,69,73,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(68,69,73,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(68,69,73,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#444549',GradientType=0 ); /* IE6-9 */
	}

	.sf-footer{
		.float:nth-child(3){
			float: right;
		}

		.l2{
			.icon{
				vertical-align: initial;
			}

			h4{
				text-align: right;
			}
		}
	}

	.wide-xs{
		width: 100% !important;
	}
}



// TABLETS AND DESKTOPS
@media (min-width: $md-screen) {
	body{
		font: {
			size: 14px;
		};

		line-height: 18.2px;
	}

	.container, .container-size{
		width: 980px;
	}

	.sf-header{
		padding-top: 50px;
		//menu

		.sf-nav-button{
			font:{
					size: 12px;
				};
		}
		.sf-menu{
			margin-left: 141px !important;

			a, .sf-nav-button{
				font:{
					size: 12px;
				}
			}

			ul {
				padding-top: 16px;
			};
		}

		hr{
			margin-top: 18px;
			margin-bottom: 62px;
			height: 1px;
		}
	}

	.sf-header.feature{
		.sf-hero{
			padding-bottom: 135px;

			h1{
				margin-bottom: 15px;
			}
			p{
				font-size: 23.8px;
			}

			.arrow{
				&.right{
					text-align: right;
				}

				&.left{
					text-align: left;
				}

				padding: {
					left: 0 !important;
					right: 0 !important;
				}

				margin-top: 22px;
			}
		}
	}

	.sf-header.feature.larger{
		.sf-hero .container{
			height: 96px;
		}
	}

	.sf-footer{
		padding-bottom: 93px;
		.logo{
			background: {
				image: url('../img/logo-footer.png');
				repeat: no-repeat;
				position-x: 2px;
				position: center;
			};

			width: 224px;
			height: 250px;
			margin-right: 76px;
		}

		.bordered-l{
			border-left: 1px solid #4d4f53;
		}

		.bordered-r{
			border-right: 1px solid #4d4f53;
		}

		h5{
			line-height: 13.2px;
			letter-spacing: 2.2px;
		}

		.footer-list{
			height: 315px;
			ul{
				font-size: 12.6px;
				margin-left: 1px;
			}
		}

		.l1{
			margin: 0 36px;

			li{
				margin-bottom: 14px;
			}
		}

		.l2{
			margin-left: 31px;
			margin-right: 61px;

			li{
				margin-bottom: 17px;
			}
		}

		.news{
			margin:{
				left: 42px;
			};

			width:227px;

			p{
				color:  #666666;
				font-size: 14px;
				font-weight: 400;
				line-height: 18.2px;
				margin:{
					left: 1px;
					bottom: 25px;
				};
			}

			.input-group{
				border-radius: 5px;
				width: 230px;

				input, .btn{
					height: 45px;
				}

				input{
					line-height: 16.8px;
					padding:{
						top: 17px;
						left: 18px;
						bottom: 19px;
					};
					color:  #878787;
				}

				.btn{
					img{
						margin-bottom: 2px;
					}
				}

			}

		}
	}

	.sf-one-column{
		.row{
			width: 500px;
			margin:{
				left: 240px;
			}

			p{
				margin-bottom: 26px !important;

				&:last-child{
					margin-bottom: 0 !important;
				}
			}
		}

		.box{
			width: 500px;

			padding: {
				right: 54px;
				left: 56px;
				top: 24px;
				bottom: 24px;
			}

			margin-bottom: 29px;

			p{
				font-size: 16px !important;
				line-height: 20px !important;
				margin-bottom: 22px !important;
				width: 522px;
			}

			.actions.content{
				.arrows{
					margin-bottom: 22px !important;
				}

				.actions.row{
					width: 390px;
					font-size: 13px;
					letter-spacing: 1.3px;

				}
			}

			box-sizing: border-box;
		}

		form{
			text-align: left;
			margin-top: 36px;

			p{
				font-size: 12px !important;
				line-height: 14.4px !important;
				margin-bottom: 26px !important;
			}
		}

	}

	.sf-two-columns{
		.first, .second{
			.content{
				max-width: 100%;
				width: 600px;
			}
		}

		.first.full-col, .second.full-col{
			.content{
				width: 100%;
				float: none;
			}
		}

		.first{
			.content{
				float: right;
			}
		}

		.second{
			.content{
				float: left;
			}
		}
	}

	.sf-form{
		label{
			font-size: 12px;
			margin-bottom: 16px;
		}

		input{
			height: 45px;
			margin-bottom: 19px
		}

		textarea{
			height: 140px;
		}

		.sf-button{
			letter-spacing: 2px;
			margin-top: 32px;
			padding: 13px 66px;
			font-size: 10px;
		}

		input[type=checkbox] + label{
			.checkbox{
				width: 18px;
				height: 18px;
				margin-right: 10px;
				border-radius: 4px;
			}
		}

		input[type=checkbox]:checked + label{
			.checkbox{
				background-image: url('../img/check.png');
			}
		}
	}


	//useful classes
	.only-mobile{
		display: none !important;
	}

	.feature-p{
		font-size: 19.9px;
		line-height: 26px;
		margin: {
			top: 0;
			bottom: 23px;
			right: 0;
			left: 1px;
		}

		&:last-child{
			margin-bottom: 0;
		}
	}

	.full, .full-md{
		width: 100% !important;
		max-width: 1200px;
	}

	.wrapper-right{
		margin-left: 50%;
	}

	.gradient{
		filter: none;
	}
	.center{
		text-align: center;
	}
	//end of useful classes

}
